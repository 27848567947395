(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobx"), require("mobxReact"), require("ReactRouterDOM"), require("moment"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("userNew", ["React", "antd", "mobx", "mobxReact", "ReactRouterDOM", "moment", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["userNew"] = factory(require("React"), require("antd"), require("mobx"), require("mobxReact"), require("ReactRouterDOM"), require("moment"), require("ReactDOM"));
	else
		root["userNew"] = factory(root["React"], root["antd"], root["mobx"], root["mobxReact"], root["ReactRouterDOM"], root["moment"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__19__) {
return 